export const initTolstoy = (pageName) => {
  document.addEventListener('scroll', () => {
    setTimeout(() => {
      if(pageName === 'Careers'){
        // window.tolstoyWidgetId='q0f05e17y1xdm'; 
        window.tolstoyWidgetId='pxt4vmexk0o3f'; 
      }
      if(pageName === 'Founder'){
        window.tolstoyWidgetId='6qg4q6e09fid9'; 
      }
      var s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://widget.gotolstoy.com/widget/widget.js';
      document.head.appendChild(s);
    }, 500)
  }, {once : true});
};


