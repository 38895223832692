export const initRecruitmentSteps = () => {
  const steps = document.querySelectorAll('.RS-recruitment-step__inner');
  let maxHeight = 0;
  
  steps.forEach(step => {
    const height = step.offsetHeight;
    maxHeight = height > maxHeight ? height : maxHeight;
  });
  
  steps.forEach(step => {
    step.style.height = `${maxHeight}px`;
  });
};